.footer {
  width: 100%;
  background: linear-gradient(180deg, #27292d 5.32%, #232529 38.01%, #17191d 71.75%, #040409 105.49%, #000005 110.76%);
  padding: 140px 119px 101px;
  display: flex;
  gap: 2px;
  justify-content: center;
  transition: all 0.3s linear;
  @media (max-width: 1032px) {
    gap: 30px;
    padding: 40px 20.5px 105px;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .socials-container {
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
      align-items: center;
    }

    .logo {
      width: 153px;
      height: 43px;
      margin-bottom: 26px;
    }

    .social-links-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16.5px;
      margin-bottom: 20px;

      .social-image {
        width: 44px;
        transition: all 0.2s linear;
      }

      .social-image:hover {
        opacity: 0.8;
      }
    }

    .copyright {
      font-family: Inter;
      font-size: 18px;
      font-weight: 400;
      line-height: 42px;
      letter-spacing: 0em;
      text-align: left;
      color: #fff;
      margin: 0;

      @media (max-width: 768px) {
        text-align: center;
      }
    }
  }

  .title {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    text-align: left;
    color: #28fffb;
    margin: 0;
    margin-bottom: 32px;
  }

  .containers {
    display: flex;
    gap: 84px;
    @media (max-width: 1032px) {
      gap: 20px;
      @media (max-width: 768px) {
        max-width: 336px;
        gap: 0;
        flex-wrap: wrap;
        column-gap: 40px;
        row-gap: 84px;
        justify-content: center;
      }
    }

    .links-container {
      display: flex;
      flex-direction: column;
      @media (max-width: 768px) {
        width: 148px;
      }

      .link {
        text-decoration: none;
        color: #fff;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        margin-bottom: 16px;
        transition: all 0.2s linear;
      }

      .link:hover {
        color: #28fffb;
      }

      .link:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
