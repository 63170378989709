.benefits {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 0 75px 97px;
  padding: 52px 0 0;
  max-width: 878px;
  position: relative;
  overflow-x: auto;
  scrollbar-width: none;

  @media (max-width: 1032px) {
    max-width: 100%;
    margin: 0 16px 55px;
    padding: 0;
    @media (max-width: 955px) {
      justify-content: flex-start;
    }
  }

  .background-cloud {
    position: absolute;
    right: -290px;
    top: 60px;
  }

  .background-plus {
    position: absolute;
    left: -160px;
    top: 240px;
  }

  .benefit {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    max-width: 286px;
    padding: 32px 26.5px 60px;
    background-color: #040b11;
    box-sizing: border-box;
    border-radius: 10px;

    .logo {
      width: 70px;
      height: 70px;
    }

    .title {
      font-family: Montserrat;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      color: #5de7f9;
      text-align: center;
      margin: 0;
      padding: 0;
      width: 233px;
    }

    .description {
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
      color: #fff;
      margin: 0;
      padding: 0;
      width: 233px;
    }
  }
}
