.content {
  background-image: url(../background-images/content/content.svg);
  max-width: 1028px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-size: 94%;
  background-position: top;
  position: relative;
  @media (max-width: 1032px) {
    background-image: none;
    margin: 0 20px;
  }

  .background-cloud {
    position: absolute;
    right: -220px;
    top: 60px;
    @media (max-width: 1032px) {
      display: none;
    }
  }

  .background-plus {
    position: absolute;
    left: -80px;
    top: 245px;
    @media (max-width: 1032px) {
      display: none;
    }
  }
}
