.partners {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .heading {
    margin-bottom: 66px;
    @media (max-width: 768px) {
      margin-bottom: 46px;
    }

    .title {
      font-family: Alfa Slab One;
      font-size: 40px;
      font-weight: 400;
      line-height: 55px;
      text-align: center;
      margin: 0;
      color: white;
      margin-bottom: 20px;
    }

    .subtitle {
      margin: 0;
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 600;
      line-height: 29px;
      text-align: center;
      color: white;
      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  .box {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 20px;
    margin: 0 75px 139px;
    max-width: 878px;
    transition: all 0.3s linear;
    @media (max-width: 1032px) {
      margin: 0 0 125px;
      @media (max-width: 768px) {
        gap: 46px;
      }
    }

    .partner {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #040b11;
      max-width: 165.5px;
    }

    .image {
      padding: 15.76px 18.91px;
    }
  }

  .background {
    background-image: url(../background-images/content/background-net.svg);
    position: absolute;
    background-size: cover;
    top: -75px;
    z-index: -1;
    width: 100vw;
    max-width: 1440px;
    height: 140%;
    @media (max-width: 1032px) {
      display: none;
    }
  }
}
