  .lead {
  margin: 0 auto;
  display: flex;
  max-width: 1043px;
  position: relative;
  margin-top: -135px;
  z-index: -1;
  transition: all 0.3s linear;
  @media (max-width: 1032px) {
    margin: 38px 20px 70px;
    flex-direction: column;
    align-items: center;
  }

  .video-container {
    max-width: 700px;
    display: none;
    margin-bottom: 57px;
    @media (max-width: 1032px) {
      display: block;
    }
  }

  .numbers-container {
    transition: all 0.3s linear;
    @media (max-width: 1032px) {
      display: flex;
      gap: 100px;
      @media (max-width: 768px) {
        display: flex;
        gap: 88px;
      }
    }
  }

  .numbers {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: absolute;
    top: 180px;
    left: -25px;
    height: fit-content;
    transition: all 0.3s linear;
    @media (max-width: 1150px) {
      left: -5px;
      @media (max-width: 1032px) {
        position: static;
      }
    }

    .number {
      display: flex;
      margin-bottom: 3px;

      .value {
        font-family: Audiowide;
        font-size: 26px;
        font-weight: 400;
        line-height: 33px;
        letter-spacing: 0em;
        text-align: left;
        color: #28fffb;
        margin: 0;
        min-width: 72px;
      }

      .plus {
        height: 13px;
        width: 13px;
      }
    }

    .number-description {
      font-family: Orbitron;
      font-size: 8px;
      font-weight: 500;
      line-height: 13px;
      letter-spacing: 0.19px;
      text-align: left;
      color: #fff;
      margin: 0;
      white-space: nowrap;
    }
  }

  .game-machine-image {
    max-width: 1038px;
    margin: 0 auto;
    padding-left: 15px;
    padding-bottom: 3px;

    @media (max-width: 1032px) {
      display: none;
    }
  }

  .numbers-right {
    right: -25px;

    @media (max-width: 1150px) {
      right: -5px;
    }

    .number-container-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  .background-cloud {
    position: absolute;
    top: 545px;
    left: -175px;
    @media (max-width: 1032px) {
      display: none;
    }
  }

  .background-plus {
    position: absolute;
    top: 565px;
    right: -75px;
    @media (max-width: 1032px) {
      display: none;
    }
  }

}
